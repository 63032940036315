<template>
  <span
    class="badge p-1"
    style="width: 65px; background-color: #ef4c0d; white-space: pre-line"
  >
    {{ name }}
  </span>
</template>
<script>
export default {
  props: ["name"],
};
</script>